/* Définition de la police 'Raleway-Bold' */
@font-face {
  font-family: 'Raleway-Bold';
  src: url('../../public/fonts/Raleway-Bold.ttf');
}

/* Définition de la police 'Raleway-Italic' */
@font-face {
  font-family: 'Raleway-Italic';
  src: url('../../public/fonts/Raleway-Italic.ttf');
}

/* Définition de la police 'Raleway-Light' */
@font-face {
  font-family: 'Raleway-Light';
  src: url('../../public/fonts/Raleway-Light.ttf');
}

/* Définition de la police 'Raleway-Regular' */
@font-face {
  font-family: 'Raleway-Regular';
  src: url('../../public/fonts/Raleway-Regular.ttf');
}

/* Styles globaux pour le corps de la page */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; /* permet de faire défiler verticalement le contenu */
  scrollbar-width: thin; /* largeur de la barre de défilement */
  scrollbar-color: #888 #f1f1f1; /* couleur de la barre de défilement et de la piste */
  box-sizing: border-box;
  padding-right: 0; /* supprime tout remplissage à droite */
}

/* Styles pour les blocs de code */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styles pour les titres de niveau 1 */
h1 {
  font-family: 'Raleway-Regular';
}

/* Styles pour les titres de niveau 3 */
h3 {
  font-family: 'Raleway-Bold';
  color: #1594b1; /* couleur spécifique pour les titres h3 */
}

/* Styles pour les paragraphes */
p {
  font-family: 'Raleway-Regular';
}

/* Styles pour les éléments span */
span {
  font-family: 'Raleway-Regular';
}

/* Styles pour les éléments avec la classe field */
.field {
  font-family: 'Raleway-Regular';
}

/* Styles spécifiques pour les navigateurs WebKit (Google Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 10px; /* largeur de la barre de défilement */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* couleur de fond de la piste de défilement */
}

::-webkit-scrollbar-thumb {
  background: #888; /* couleur de la barre de défilement */
  border-radius: 4px; /* bords arrondis de la barre de défilement */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* couleur de la barre de défilement au survol */
}

/* Styles pour mettre en surbrillance un texte */
.highlight {
  background-color: orange; /* couleur de fond pour la mise en surbrillance */
}

/* Conteneur pour les messages de chargement */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* prend toute la hauteur de la fenêtre */
  width: 100vw; /* prend toute la largeur pour éviter les défilements horizontaux */
  position: fixed; /* position fixe pour éviter les décalages de mise en page */
  top: 0;
  left: 0;
  overflow: hidden; /* empêche le défilement du corps pendant le chargement */
  z-index: 9999; /* s'assure que le conteneur est au-dessus des autres éléments */
}

/* message de chargement */
.loading-message {
  margin-top: 16px; /* ajuste l'espacement si nécessaire */
  text-align: center;
}

/* message d'erreur lorsqu'aucune donnée n'est disponible */
.no-data-error-message {
  margin-top: 100px; /* ajuste l'espacement si nécessaire */
  text-align: center;
}