/* Conteneur principal pour la fiche de données */
.data-sheet {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  max-width: 1200px;
  margin: 20px auto;
  margin-top: 115px;
}

/* Style pour chaque champ individuel dans la fiche de données */
.field {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  max-width: 1250px;
  margin: 15px auto;
}

/* Conteneur pour les boutons de l'en-tête */
.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto 20px auto;
  margin-top: 30px;
  padding: 0 20px;
}

/* Style pour chaque bouton dans l'en-tête */
.header-button {
  padding: 10px 15px;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  border: none;
}

/* Effet au survol pour les boutons de l'en-tête */
.header-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Effet lors du clic sur les boutons de l'en-tête */
.header-button:active {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style pour le bouton "Ouvrir avec..." */
.open-with-button {
  padding: 10px 15px;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  border: none;
  margin-right: 5px;
}

/* Effet au survol pour le bouton "Ouvrir avec..." */
.open-with-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Effet lors du clic sur le bouton "Ouvrir avec..." */
.open-with-button:active {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Style pour l'image dans le bouton "Ouvrir avec..." */
.open-with-button-img {
  margin-left: 7.5px;
  width: 20px;
  height: 20px;
  margin-bottom: -3.5px;
}

/* Conteneur pour l'en-tête */
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: -15px;
}

/* Section gauche de l'en-tête */
.header-left {
  max-width: 60%;
  margin-top: 20px;
}

/* Section droite de l'en-tête, avec positionnement fixe */
.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  margin-top: 19px;
}

/* Ligne de boutons dans l'en-tête */
.header-buttons-row {
  display: flex;
  margin-top: 20px;
}

/* Style pour le titre de la fiche de données */
.data-sheet-title {
  font-size: 24px;
  color: #0056b3;
  margin-bottom: 10px; 
  margin-top: 10px;
}

/* Style pour le compteur d'objets dans la fiche de données */
.data-sheet-objects-count {
  font-size: 15px;
  color: black;
  margin-left: 10px; 
}

/* Conteneur pour les tags */
.tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Conteneur pour les tags affichés en ligne */
.tags-inline {
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: 5px;
}

/* Style pour chaque tag */
.tag {
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 5px 8px;
  font-size: 15px;
  margin-right: 5px;
}

/* Style pour le sous-titre de la fiche de données */
.data-sheet-subtitle {
  font-size: 18px;
  color: #000000;
  margin-top: 30px;
}

/* Style pour chaque section de la fiche de données */
.section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Style pour le titre de chaque section */
.section-title {
  font-size: 25px;
  color: #0056b3;
  margin-bottom: 30px;
  margin-top: 15px;
}

/* Contenu de chaque section de la fiche de données */
.section-content {
  font-size: 16px;
  color: #333;
}

/* Style spécifique pour les sections de contact */
.section.contact {
  flex: 0 0 49.25%;
  box-sizing: border-box;
}

/* Conteneur pour les sections divisées */
.split-section {
  display: flex;
  justify-content: space-between;
}

/* Style pour la table de données */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

/* Conteneur pour la table, avec défilement horizontal */
.table-container {
  overflow-x: auto;
}

/* Style pour les cellules de la table */
.data-table th,
.data-table td {
  border: 1px solid #ccc;
  padding: 7.5px; /* Hauteur de cellule réduite */
  font-size: 13px; /* Taille de police réduite */
  white-space: nowrap; /* Forcer le contenu à tenir sur une seule ligne */
}

/* Style pour les en-têtes de la table */
.data-table th {
  background-color: #f0f0f0;
  font-size: 13px; /* Taille de police augmentée pour les en-têtes */
  padding: 10px; /* Padding augmenté pour les en-têtes */
}

/* Style pour les lignes impaires de la table */
.data-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
