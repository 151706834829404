/* Conteneur principal pour la qualité des métadonnées */
.metadata-quality-container {
  position: relative;
  margin-top: 3px;
  display: inline-block;
  align-items: center;
  font-size: 1rem;
}

/* Style de la barre de qualité des métadonnées */
.metadata-quality {
  position: relative;
  width: 150px;
  height: 20px;
  display: flex;
  align-items: center;
}

/* Icône d'information */
.info-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

/* Conteneur de la barre de progression de la qualité des métadonnées */
.metadata-quality-bar {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

/* Conteneur de la progression des cartes de données */
.data-card-progress-container {
  display: flex;
  align-items: center;
}

/* Conteneur de la progression des cartes de données pour les datagences */
.data-card-progress-container-datagences {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

/* Date de progression des cartes de données */
.data-card-progress-date {
  color: #888;
  margin-left: 10px;
  margin-right: 5px;
  align-self: center;
  margin-top: 3px;
  font-size: 1rem;
}

/* Conteneur principal pour la qualité des métadonnées */
.metadata-quality-container {
  margin-top: 3px;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

/* Remplissage de la barre de qualité des métadonnées */
.metadata-quality-fill {
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
}

/* Valeur affichée de la qualité des métadonnées */
.metadata-quality-value {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #fff;
  text-shadow: 1px 1px 2px #000, 0 0 1px #000, 0 0 10px #000; /* plus prononcé pour une meilleure visibilité */
}

/* Détails des champs de qualité des métadonnées */
.metadata-quality-details {
  position: absolute;
  width: 250px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 20;
}

/* Position des détails en dessous */
.metadata-quality-details.below {
  top: 25px;
  left: 0;
}

/* Position des détails au-dessus */
.metadata-quality-details.above {
  bottom: 25px;
  left: 0;
}

/* Style de chaque détail de qualité des métadonnées */
.metadata-quality-detail {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

/* Icône de validité */
.metadata-quality-detail .valid,
.metadata-quality-detail .invalid {
  margin-right: 5px;
}

/* Couleur pour les détails valides */
.metadata-quality-detail .valid {
  color: green;
}

/* Couleur pour les détails invalides */
.metadata-quality-detail .invalid {
  color: red;
}
