/* Styles généraux pour la carte de données */
.data-card {
  width: 550px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  margin-bottom: 5px;
  text-align: left;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, opacity 0.5s;
  z-index: 1;
  position: relative;
}

/* Effet au survol de la carte de données */
.data-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

/* Animation de fondu pour l'apparition de la carte */
.data-card.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Animation de fondu pour la disparition de la carte */
.data-card.fade-out {
  opacity: 1;
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

/* Style pour le titre de la carte de données */
.data-card-title {
  color: #0056b3;
  margin-top: 7.5px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* limite le titre à une seule ligne */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style pour le titre de la carte de données spécifique à Datagences */
.data-card-datagences-title {
  color: #0056b3;
  margin-top: 10px;
  margin-bottom: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* limite le titre à deux lignes */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px; /* hauteur fixe pour deux lignes */
  line-height: 20px; /* ajustement de la hauteur de ligne */
}

/* Conteneur pour les tags de la carte de données */
.data-card-tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px; /* espace entre les tags et la description */
}

/* Style pour chaque tag dans la carte de données */
.data-card-tag {
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 5px 8px;
  margin: 5px;
  margin-left: 0;
  margin-top: 0;
  font-size: 15px;
}

/* Style pour la description de la carte de données */
.data-card-description {
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* limite la description à deux lignes */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px; /* hauteur fixe pour deux lignes */
  line-height: 20px; /* ajustement de la hauteur de ligne */
}

/* Conteneur pour les informations supplémentaires dans la carte de données */
.data-card-info {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

/* Style pour les images dans la carte de données */
.data-card-img {
  width: 104px;
  margin-right: 30px;
  margin-top: 5px;
  user-select: none;
  pointer-events: none;
}

/* Conteneur pour les informations spécifiques à Datagences */
.data-card-info-datagences {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 4px;
}

/* Style pour les images spécifiques à Datagences */
.data-card-img-datagences {
  width: 224px;
  margin-right: 5px;
  margin-top: -3px;
  padding-left: 112px;
  margin-bottom: -10px;
  user-select: none;
  pointer-events: none;
}

/* Style pour la thématique de la carte de données */
.data-card-thematique {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* limite la thématique à une seule ligne */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Style pour le bandeau "Nouveau" sur la carte de données */
.new-banner {
  position: absolute;
  top: 55px;
  right: -3px;
  transform: rotate(45deg);
  transform-origin: top right;
  width: 80px;
  z-index: 10;
}
