/* Style de base pour le bouton de tri, ajoutant une transition pour la couleur de fond et du texte */
.sort-button {
  transition: background-color 0.3s, color 0.3s;
  margin-top: 10px;
}

/* Style appliqué lorsque le bouton de tri est actif, change la couleur de fond et du texte */
.sort-button.active {
  background-color: black !important;
  color: white !important;
}
