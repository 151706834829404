/* Bouton thématique principal */
.theme-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}
  
/* Image à l'intérieur du bouton thématique */
.theme-button img {
    width: 150px;
    height: 75px;
}

/* Effet au survol du bouton thématique */
.theme-button:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
