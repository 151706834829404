/* Style pour le texte d'aide sous les champs de formulaire */
.MuiFormHelperText-root {
  margin-top: 7.5px !important;
  margin-bottom: 10px !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

/* Style pour l'entrée du champ de description, ajustant la hauteur et l'alignement */
.description-input-root .MuiOutlinedInput-input {
  height: auto;
  display: flex;
  align-items: flex-start;
  width: 1800px;
}

/* Style pour la racine du champ d'entrée, définissant la hauteur et l'alignement */
.input-field .MuiOutlinedInput-root {
  height: 45px;
  display: flex;
  align-items: center;
}

/* Style pour l'élément d'entrée à l'intérieur du champ, ajustant le padding et l'alignement */
.input-field .MuiOutlinedInput-input {
  padding: 10px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

/* Style pour l'étiquette des champs d'entrée, ajustant la position et la taille de la police */
.input-label {
  font-size: 0.875rem;
  top: 25%;
  transform: translateY(-25%);
  font-size: 1rem;
  margin-top: -4.75px;
}

/* Style pour l'étiquette lorsque le champ est focalisé ou réduit */
.input-label.Mui-focused, .input-label.MuiInputLabel-shrink {
  margin-top: 0.5px;
}

/* Style pour les messages d'erreur sous les champs de formulaire */
.error-message {
  margin-top: 3px;
  margin-left: 5px;
  color: red;
  font-size: 0.75rem;
}

/* Augmente l'espacement lorsque le conteneur d'entrée a une erreur */
.input-container.error {
  margin-bottom: 10px;
}

/* Conteneur pour les mots-clés, utilisant un affichage en colonne */
.keywords {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

/* Style pour les boutons d'icône dans le conteneur des mots-clés, alignement à gauche */
.keywords .MuiIconButton-root {
  align-self: flex-start;
}
