/* Barre supérieure fixe */
.top-bar {
  position: fixed;
  width: calc(100vw - 8px); /* ensures top bar accounts for scrollbar */
  background-color: #f5f5f5;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  z-index: 1000;
  top: 0;
}

/* Contenu centré dans la barre supérieure */
.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 49%;
  transform: translateX(-50%);
  text-align: center;
}

/* Texte du titre dans la barre supérieure */
.title-text {
  font-size: 1.5rem;
  margin-right: 2px;
}

/* Logo AudéLor dans la barre supérieure */
.audelor-logo {
  height: 25px;
  margin-top: 4px;
}

/* Ligne des boutons OneNote */
.onenote-buttons-row {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 35px;
}

/* Bouton OneNote */
.onenote-button {
  padding: 10px 15px;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  border: none;
}

/* Effet au survol du bouton OneNote */
.onenote-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Effet lorsque le bouton OneNote est activé */
.onenote-button:active {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

/* Image dans le bouton OneNote */
.onenote-button-img {
  margin-left: 7.5px;
  margin-right: 7.5px;
  width: 20px;
  height: 20px;
  margin-bottom: -3.5px;
}

/* Conteneur des boutons dans l'en-tête */
.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1290px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 49.05%;
  transform: translateX(-50%);
  top: -14px;
  padding: 0 20px;
}

/* Bouton dans l'en-tête */
.header-button {
  padding: 10px 15px;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  border: none;
}

/* Effet au survol du bouton dans l'en-tête */
.header-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Effet lorsque le bouton dans l'en-tête est activé */
.header-button:active {
  transform: translateY(-2px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
